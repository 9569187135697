import { h } from 'preact';
import { styled } from 'goober';
import { useContext } from 'preact/hooks';
import { forwardRef } from 'preact/compat';

import ToggleIcon from './toggle-icon';
import WolficonSearch from '../../assets/icons/wolficon-resource-search';
import WolficonClose from '../../assets/icons/wolficon-close';

const ButtonWrapper = styled('button')`
    height: 36px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
    background: #900;
    border: none;
    border-radius: 0;
    padding: 4px 12px;
    transition: background 0.2s ease-out;
    z-index: 99997 !important;

    &:hover {
        background-color: #700;
        cursor: pointer;
    }

    &:focus {
        outline: 1px solid #FFF;
        outline-offset: -1px;
    }
`;
function SearchButton({ isOpen, setIsOpen }, ref) {
    return (
        <ButtonWrapper ref={ ref } class='search-button' aria-label="NC State resources" role="search" onClick={ () => setIsOpen( ! isOpen ) }>
            <ToggleIcon isOpen={!isOpen}>
                <WolficonSearch />
            </ToggleIcon>
            <ToggleIcon isOpen={isOpen}>
                <WolficonClose />
            </ToggleIcon>
        </ButtonWrapper>
    );
}

export default forwardRef( SearchButton );
