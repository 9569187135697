import { h } from 'preact';

const WolficonClose = () => (
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
  	 viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;">
  <g>
  	<g>
  		<g>
  			<g>
  				<g>
  					<polygon class="st0" points="8.8,8 13,12.2 13,12.2 12.2,13 12.1,12.9 8,8.8 3.8,13 3,12.2 3.1,12.1 7.2,8 3,3.8 3.1,3.7
  						3.8,3 3.9,3.1 8,7.2 12.2,3 12.3,3.1 13,3.8 12.9,3.9 					"/>
  				</g>
  			</g>
  		</g>
  	</g>
  </g>
  </svg>
);

export default WolficonClose;
