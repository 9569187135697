import { h, Fragment } from 'preact';
import { styled } from 'goober';

import Icon from '../icon';
import WolficonArrowRightBold from '../../assets/icons/wolficon-arrow-right-bold';

const NoWrapContainer = styled('span')`
    display: inline-block;
    text-decoration: inherit;

    .arrow-indicator {
      height: 1em;
      max-width: 1.5em;
	  
	  	svg {
			height: 1em;
		}
    }
`;

export default function ArrowDecorator({content}) {
  let n = content.split( ' ' );
  let lastWord = n.pop();

    let contentWithArrow = [ `${n.join( ' ' )  } ` ];
    contentWithArrow.push(
      <NoWrapContainer key={ lastWord }>
        { lastWord }&nbsp;
        <span class="arrow-indicator">
          <Icon>
            <WolficonArrowRightBold />
          </Icon>
        </span>
      </NoWrapContainer>
    );

  return (
    <>
      { contentWithArrow }
    </>
  );
}