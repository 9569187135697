import habitat from "preact-habitat";

import NCStateUtilityBar from "./components/ncstate-utility-bar";
let _habitat = habitat(NCStateUtilityBar);

_habitat.render({
  selector: '#ncstate-utility-bar',
  defaultProps: {
      color: 'black',
      showBrick: 1,
      searchLabel: "Search",
      topOffset: 36,
      wolfAlert: 1,
      lowPriority: 0,
      wolfAlertURL: "https://cdn.ncsu.edu/wolfalert/alert.json",
      cseId: "",
  },
  clean: true
});
