import { h, Fragment, createContext } from 'preact';

export const themes = {
  red: {
    searchPaneBg: "#7E0000",
    resultsPaneBg: "#5E0000",
    searchInputBg: "transparent",
    searchInputBorder: '3px solid #FFFFFF',
    linkColor: "#FFFFFF",
    linkInteractColor: "#CCCCCC",
    buttonColor: "#FFFFFF",
  },
  black: {
    searchPaneBg: "#222222",
    resultsPaneBg: "#111111",
    searchInputBg: "transparent",
    searchInputBorder: '3px solid #FFFFFF',
    linkColor: "#FFFFFF",
    linkInteractColor: "#CCCCCC",
    buttonColor: "#FFFFFF",
  },
  gray: {
    searchPaneBg: "#f3f3f3",
    resultsPaneBg: "#FFFFFF",
    searchInputBg: "#CCCCCC",
    searchInputBorder: '0',
    linkColor: "#333333",
    linkInteractColor: "#000000",
    buttonColor: "#333333",
  }
};

export default ThemeContext = createContext(themes.red);