import { h } from 'preact';
import BrickAnchor from './home-anchor-style';

export default function HomeAnchor({useBrick}) {
    return (
        <BrickAnchor
            useBrick={ useBrick }
            id="ncstate-home-link"
            href="https://www.ncsu.edu">
            NC State Home
        </BrickAnchor>
    );
}
