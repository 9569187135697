import "preact/debug";
import { h, Fragment } from 'preact';
import { useContext, useState, useEffect, useRef } from 'preact/hooks';

import UtilityRibbon from './utility-ribbon';
import WolfAlert from './wolf-alert';
import DropdownPane from './dropdown-pane';

import { styled, setup } from 'goober';
import { prefix } from 'goober/prefixer';

import ThemeContext, { themes } from '../styles/color-theme-context';
import GlobalStyles from '../styles/global-styles';

const colorTheme = ()=> useContext(ThemeContext);

setup(h, prefix, colorTheme);

const NCStateUtilityBar = ( props ) => {
  const [ isOpen, setIsOpen ] = useState( false );
  const [ wolfAlertData, setWolfAlertData ] = useState( false );
  const [ windowWidth, setWindowWidth] = useState(0);
  const { wolfAlert, wolfAlertURL, lowPriority, color, maxWidth, showBrick, alternateOffset, searchLabel, cseId } = props;

  const RibbonRef = useRef(null);
  const WolfAlertRef = useRef(null);

  const [ calculatedOffset, setCalculatedOffset ] = useState(0);

  // Retrieve the wolf Alert data from the provided url on load
  const getData = () => {
    fetch(wolfAlertURL, {cache: "no-store"}) // cache: no store ensures that it will always check the json file instead of loading from the cache
    .then(res => res.json())
    .then((out) => {
        setWolfAlertData(out);
    })
    .catch(err => {
        throw err
    });
  }

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const calculateOffset = () => {
    let currentOffset = !!alternateOffset ? alternateOffset : RibbonRef.current.base.clientHeight;
    setCalculatedOffset( currentOffset );
  };

  const onEscape = (event) => {
    if ( event.key === `Escape` || event.keyCode === 27 ) {
        setIsOpen(false);
    }
  };

  useEffect(() => {
    let timeoutId = null;
    const windowResizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setWindowWidth(getWidth()), 150);
    };

    document.addEventListener( "keydown", onEscape, false );
    window.addEventListener( "resize", windowResizeListener, false);

    return () => {
      document.removeEventListener("keydown", onEscape, false );
      window.removeEventListener("resize", windowResizeListener, false );
    }
  }, [] );

  useEffect(() => {
    calculateOffset();
    // Only retrieve data if the wolfAlert flag has been set
    if(wolfAlert){
      getData();
    }
  }, [windowWidth] );

  let priorityCheck = (wolfAlertData.priority === "high") || (lowPriority === "1" && wolfAlertData.priority === "low");

  return (
    <>
      <GlobalStyles />
      <ThemeContext.Provider value={ themes.[color] }>
        <div class='ncstate-utility-bar' role="navigation" aria-label="NC State Brand Utility Bar">
          <UtilityRibbon
            ref={ RibbonRef }
            maxWidth={ maxWidth }
            showBrick={ showBrick }
            isOpen={ isOpen }
            setIsOpen={ setIsOpen }
          />
          { !!wolfAlert && !!wolfAlertData.active && !!priorityCheck &&
            <WolfAlert
              ref={ WolfAlertRef }
              messageHeadline={ wolfAlertData.messageHeadline }
              messageContent={ wolfAlertData.messageContent }
              cta={ wolfAlertData.cta }
              url={ wolfAlertData.url }
              priority={ wolfAlertData.priority }
            />
          }
          {
            <DropdownPane
              isOpen={ isOpen }
              maxWidth={ maxWidth }
              searchLabel={ searchLabel }
              hasAlert={ wolfAlertData.active }
              topOffset={ calculatedOffset }
              cseId={ cseId }
            />
          }
        </div>
      </ThemeContext.Provider>
    </>
  );
}

export default NCStateUtilityBar;
