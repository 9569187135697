import { h } from 'preact';
import { styled } from 'goober';

export const SearchForm = styled('form')`
    &#dropdown-search-form {
        position: relative;
        width: 100%;

        #utility-bar-search-submit,
        label {
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            white-space: nowrap;
            border: 0;
        }

        .search-input-icon {
            display: block;
            position: absolute;
            top: 6px;
            left: 4px;
            padding: 0.25rem;
            height: 36px;
            width: 36px;
            color: ${ ({theme})=>theme.linkColor };
        }

        input {
            height: 48px;
            width: 100%;
            background-color: ${ ({theme})=>theme.searchInputBg };
            padding: 0.5rem 0.5rem 0.5rem 36px;
            font-size: 20px;
            border-radius: 0;
            color: ${ ({theme})=>theme.linkColor };
            border: ${ ({theme})=> theme.searchInputBorder };

            &::placeholder {
                opacity: 1;
                color: ${ ({theme})=>theme.linkColor };
            }

            &:focus {
                outline: 1px solid ${ ({theme})=>theme.linkColor };
            }

            &[type=search] {
              -webkit-appearance: none;
            }

            &[type=search]::-webkit-search-cancel-button {
                -webkit-appearance:none;
                height: 25px;
                width: 25px;
                cursor: pointer;
                background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="${ ({theme})=> theme.linkColor.replace('#', '%23') }" viewBox="0 0 36 36"><path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z"/></svg>') no-repeat center center;
            }
        }

        @media (min-width: 768px) {
            input {
                height: 56px;
                font-size: 28px;
                padding: 0.875rem 0.875rem 0.875rem 56px;
            }

            .search-input-icon {
                top: 0px;
                left: 0px;
                width: 56px;
                height: 56px;
                padding: 0.875rem;
            }
        }

    }
`;
