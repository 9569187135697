import { h } from "preact";
import { createGlobalStyles } from "goober/global";

const GlobalStyles = createGlobalStyles`

    .ncstate-utility-bar-lock-scroll {
        height: 100% !important;
        overflow: hidden !important;
    }

    .ncstate-utility-bar {
        html, body, div, span, applet, object, iframe,
        h1, h2, h3, h4, h5, h6, p, blockquote, pre,
        a, abbr, acronym, address, big, cite, code,
        del, dfn, em, img, ins, kbd, q, s, samp,
        small, strike, strong, sub, sup, tt, var,
        b, u, i, center,
        dl, dt, dd, ol, ul, li,
        fieldset, form, label, legend,
        table, caption, tbody, tfoot, thead, tr, th, td,
        article, aside, canvas, details, embed,
        figure, figcaption, footer, header, hgroup,
        menu, nav, output, ruby, section, summary,
        time, mark, audio, video {
            margin: 0;
            padding: 0;
            border: 0;
            font: inherit;
            font-size: 100%;
            vertical-align: baseline;
        }

        html {
            line-height: 1;
        }

        ol, ul {
            list-style: none;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
        }

        caption, th, td {
            text-align: left;
            font-weight: normal;
            vertical-align: middle;
        }

        q, blockquote {
            quotes: none;
            &:before, &:after {
                content: "";
                content: none;
            }
        }

        a img {
            border: none;
        }

        input,
        button {
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }

        button, a {
            .arrow-indicator {
                display: inline-flex;
                transition: transform 0.2s ease-out;
                backface-visibility: hidden;
                height: 100%;
                width: auto;
                white-space: nowrap;
            }

            &:hover,
            &:focus {
                .arrow-indicator {
                  transform: translateX(4px);
                }
            }
        }

        *, *:before, *:after {
            -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
        }

        font-family: NCSTSansSerif, arial, sans-serif;
        font-size: 14px;
        line-height: 1;
        *zoom: 1;

        a {
            text-decoration: none;
        }

        ul li {
            list-style: none;
            list-style-type: none;
        }
    }
`;

export default GlobalStyles;
