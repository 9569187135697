import { h } from 'preact';
import { styled } from 'goober';

import Icon from '../icon';

const IconWrapper = styled('span')`
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: ${ ({isOpen}) => isOpen ? 'inline-flex' : 'none' };
`;

export default function ToggleIcon ({isOpen, children}) {
    return (
        <IconWrapper isOpen={ isOpen } >
            <Icon>
                { children }
            </Icon>
        </IconWrapper>
    );
}