import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import Icon from '../icon';
import ArrowDecorator from '../arrow-decorator';
import WolficonAlert from '../../assets/icons/wolficon-alert';
import WolfAlertContainer from './wolf-alert-styles';

function WolfAlert({ messageHeadline, messageContent, cta, url, messageWidth, priority }, ref ) {
    let highPriority = priority === "high";
    return (
        <WolfAlertContainer  ref={ ref } id='wolf-alert' messageWidth={ messageWidth } priority={ priority } >
            <span class='alert-inner-container'>
                { !! highPriority &&
                  <Icon id="wolf-alert-icon">
                      <WolficonAlert priority={ priority }/>
                  </Icon>
                }
                <a id='wolf-alert-link' href={ url } >
                    <div id="wolf-alert-message-headline">
                        { messageHeadline }
                    </div>
                    { !! messageContent &&
                        <div id="wolf-alert-message-content">
							{ <span dangerouslySetInnerHTML={{ __html: messageContent }}></span> }
                        </div>
                    }
                    <div id="wolf-alert-cta">
                        <ArrowDecorator
                            content={ cta }
                        />
                    </div>
                </a>
            </span>
        </WolfAlertContainer>
    );
}

export default forwardRef( WolfAlert );
