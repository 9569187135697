import { h } from 'preact';
import { useEffect, useContext } from 'preact/hooks';

import ResourceLinks from '../resource-links';
import SearchBox from '../search-box';

import ThemeContext from '../../styles/color-theme-context';

import { DropdownPaneWrapper, SearchPane, ResultsPane } from './dropdown-pane-styles';

export default function DropdownPane({ isOpen, maxWidth, searchLabel, hasAlert, topOffset, cseId }) {
    const theme = useContext(ThemeContext);

    const togglePageLock = () => {
        if (typeof document !== "undefined") {
            document.documentElement.classList.toggle("ncstate-utility-bar-lock-scroll", !!isOpen);
            document.body.classList.toggle("ncstate-utility-bar-lock-scroll", !!isOpen);
        }
    }

    useEffect(() => {
        togglePageLock();
    }, [isOpen] );

    //cleanup  on unmount
    useEffect(() => {
        return () => {
            document.documentElement.classList.remove("ncstate-utility-bar-lock-scroll");
            document.body.classList.remove("ncstate-utility-bar-lock-scroll");
        };
    }, []);

    return (
        <DropdownPaneWrapper id='ncstate-utility-dropdown-pane' topOffset={ topOffset } isOpen={ isOpen } hasAlert={ hasAlert }>
          <div class='dropdown-inner-wrapper'>
            <SearchPane id='ncstate-utility-search-pane'>
                <div class='dropdown-inner-container' >
                    <SearchBox
                        searchLabel={ searchLabel }
                        cseId={ cseId }
                    />
                </div>
            </SearchPane>
            <ResultsPane id='ncstate-utility-results-pane'>
                <div class='dropdown-inner-container'>
                    <ResourceLinks
                        cta="More resources"
                        ctaURL="https://www.ncsu.edu/resources/"
                    />
                </div>
            </ResultsPane>
          </div>
        </DropdownPaneWrapper>
    );
}
