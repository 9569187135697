import { h } from 'preact';

import Icon from '../icon';
import WolficonSearch from '../../assets/icons/wolficon-search';

import { SearchForm } from './search-box-styles';

export default function SearchBox({ searchLabel, cseId }) {
  return (
    <div id='dropdown-search-box'>
        <SearchForm id="dropdown-search-form" action="https://www.ncsu.edu/search" name="navsearchform">
            <span class="search-input-icon">
                <Icon>
                    <WolficonSearch />
                </Icon>
            </span>

            <input type="search" id="utility-bar-search-field" placeholder={ searchLabel } name="q" />
            <label for="utility-bar-search-field">{ searchLabel }</label>

            <button type="submit" aria-label="Search" id='utility-bar-search-submit'>
                <Icon>
                    <WolficonSearch />
                </Icon>
            </button>
            <label for="utility-bar-search-submit">Search</label>

            {typeof(cseId) != 'undefined' && cseId.length !== 0 && <input id="navcx" name="cx" type="hidden" value={ cseId } />}
        </SearchForm>
    </div>
  );
}
