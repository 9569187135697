import { h } from 'preact';
import { styled } from 'goober';

const Wolficon = styled('span')`
    height: 100%;
    width: auto;
    padding: 4px;
    svg {
        height: 100%;
        min-height: 1em;
        width: auto;
        fill: currentColor;
    }
`;

export default function Icon ({id, children}) {
    return (
        <Wolficon id={id}>
            { children }
        </Wolficon>
    );
}