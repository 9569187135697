import { h } from 'preact';
import { styled } from 'goober';

export default WolfAlertContainer = styled('div')`
    &#wolf-alert {
        display: flex;
        align-items: center;
        line-height: 1.6;
        justify-content: center;
        position: relative;
        width: 100%;
        background-color: ${({priority}) => !!priority && priority==='low' ? '#666' : '#FAC800' };
        color: ${({priority}) => !!priority && priority==='low' ? '#FFF' : '#333' };
        padding: 1rem;
        padding-top: 2rem;
        font-size: 1rem;
        z-index: 99989!important;

        @media (min-width: 768px) {
            font-size: 1.125rem;
        }

        #wolf-alert-message-headline {
            font-weight: 700;
        }

        #wolf-alert-message-content {
            font-weight: 300;
            margin-top: 10px;
        }

        #wolf-alert-cta {
            font-weight: 700;
            margin-top: 10px;
        }

        .alert-inner-container {
            max-width: 100%;
            margin: 1.2rem auto;
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            #wolf-alert-icon {
                width: 28px;
                align-self: flex-start;
            }

            a {
                display: inline-block;
                text-decoration: none;
                margin: 4px 10px 0;
                padding: 4px 2px;
                line-height: 1.2em;
                transition: color .2s ease-out 50ms, box-shadow .1s ease-in;
                color: ${({priority}) => !!priority && priority==='low' ? '#FFF' : '#333' };

                &:focus {
                    outline: 2px solid ${({priority}) => !!priority && priority==='low' ? '#ccc' : '#000' };
                    outline-offset: 4px;
                }

                &:hover {
                    color: ${({priority}) => !!priority && priority==='low' ? '#ccc' : '#000' };
                }

            }

            @media( min-width: 576px) {
                max-width: ${({ messageWidth }) => !!messageWidth ? messageWidth : `calc(10 / 12 * 100%)`};
            }

            @media (min-width: 768px) {
                max-width: ${({ messageWidth }) => !!messageWidth ? messageWidth : `calc(8 / 12 * 100%)`};
            }
        }
    }
`;
