import { h } from 'preact';
import { forwardRef } from 'preact/compat';

import { styled } from 'goober';

import HomeAnchor from '../home-anchor';
import SearchButton from '../search-button';

const RibbonWrapper = styled('div')`
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 36px;
    z-index: 99997 !important;
    background: #333;
    display: flex;
    justify-content: center;

    #ribbon-inner-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        height: 36px;
        padding: 0 15px;
        width: 100%;
        maxWidth: 1500px;
    }
`;

function UtilityRibbon({maxWidth, showBrick, isOpen, setIsOpen}, ref) {
    return (
        <RibbonWrapper ref={ ref }>
            <div id="ribbon-inner-container">
                <HomeAnchor
                    useBrick={ showBrick }
                />
                <SearchButton
                    setIsOpen={ setIsOpen }
                    isOpen={ isOpen }
                />
            </div>
        </RibbonWrapper>
    );
}

export default forwardRef(UtilityRibbon);